@media screen and (min-width: 641px) {
  .split-wrap .split-item {
    padding-top: 0px;
    padding-bottom: 0px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }
}

.split-wrap .split-item {
  padding-top: 0px;
  padding-bottom: 0px;
}
